import React, { useState, useEffect } from 'react'
import { 
    Divider, Table, TableContainer, TableHead, TableRow, TableBody, Paper,
    Button, LinearProgress, FormControl, InputLabel, MenuItem, Select,
    Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, 
    CircularProgress, TextField
} from "@material-ui/core";
import { ArrowForward } from "@material-ui/icons";
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { API } from "aws-amplify";
import { StyledTableCell, StyledTableRow } from '../components/StyledTable';
import { FormatDateTime } from '../utils';
import { ConfirmDialog } from '../components/ConfirmDialog';
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) => 
    createStyles({
        progress: {
            backgroundColor: "#ff9800"
        },
        progressBackground: {
            backgroundColor: "#ffe0b2"
        },
        formControl: {
            margin: theme.spacing(2),
            minWidth: 120,
        },
        actionButton: {
            marginRight: 8,
            marginBottom: 8,
            width: 100
        }
    })
);

interface Props {
    user?: any | null
}

interface DataPrivacyInfo {
    pk: string;
    sk: string;
    pk1: string;
    sk1: string;
    requestedAt: string;
    firstName: string;
    surname: string;
    email: string;
    completedBy: string;
    completedAt: string;
}

export default function DataPrivacy({ user }: Props) {
    const classes = useStyles()
    
    const [loading, setLoading] = useState<boolean>(false)
    const [dataPrivacyQueue, setDataPrivacyQueue] = useState<Array<DataPrivacyInfo>>([])
    const [status, setStatus] = useState<string>('Requested')
    const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false)
    const [selected, setSelected] = useState<DataPrivacyInfo>() 
    const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
    const [confirmError, setConfirmError] = useState<string>()
    const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false)
    const [openAlertDialog, setOpenAlertDialog] = useState<boolean>(false)

    const navigate = useNavigate()

    useEffect(() => {
        setLoading(true)
        const s = (status === "Requested") ? "request" : "completed";
        API.get("", `/data-privacy/${s}`, "")
        .then((response: Array<DataPrivacyInfo>) => {
            if(response) {
                if(status === "Requested") {
                    setDataPrivacyQueue(response.sort((a, b) => (a.requestedAt < b.requestedAt) ? 1 : -1))
                } else {
                    setDataPrivacyQueue(response.sort((a, b) => (a.completedAt < b.completedAt) ? 1 : -1))
                }
                
            }
            setLoading(false)
        })
        .catch((error: any) => {
            setLoading(false)
            console.log("Error: fetching data privacy queue", error)
        })
    }, [status])

    const getDataPrivacyQueue = () => {
        setLoading(true)
        const s = (status === "Requested") ? "request" : "completed";
        API.get("", `/data-privacy/${s}`, "")
        .then((response: Array<DataPrivacyInfo>) => {
            if(response) {
                if(status === "Requested") {
                    setDataPrivacyQueue(response.sort((a, b) => (a.requestedAt < b.requestedAt) ? 1 : -1))
                } else {
                    setDataPrivacyQueue(response.sort((a, b) => (a.completedAt < b.completedAt) ? 1 : -1))
                }
            }
            setLoading(false)
        })
        .catch((error: any) => {
            setLoading(false)
            console.log("Error: fetching data privacy queue", error)
        })
    }

    const onConfirmSubmit = () => {
        if(selected && user) {
            setConfirmLoading(true)
            let params = {
                body: {
                    data: selected,
                    user: user.username
                }
            }
            API.post("", "/data-privacy/process", params)
            .then(() => {
                setConfirmLoading(false)
                setOpenConfirmDialog(false)
                getDataPrivacyQueue()
            })
            .catch((error: any) => {
                setConfirmLoading(false)
                setConfirmError("Error: unable to process request")
                console.log("Error: completing request", error)
            })
        } else {
            setConfirmError("Error: unable to process request")
        }
    }

    const getType = (type: string) => {
        if(type.includes("PRIVACY")) {
            return "Privacy"
        }
        return "Delete"
    }

    const getStatus = (status: string) => {
        if(status.includes("REQUESTED")) {
            return "Requested"
        }
        return "Completed"
    }

    const handleDeleteAction = (item: DataPrivacyInfo) => {
        for(let i = 0; i < dataPrivacyQueue.length; i++) {
            if(dataPrivacyQueue[i].sk1 === item.sk1 && dataPrivacyQueue[i].sk !== item.sk) {
                setOpenAlertDialog(true)
                return
            }
        }
        setOpenDeleteDialog(true)
        setSelected(item)
    }

    return (
        <div>
            <div style={{display: "flex"}}>
                <h2 style={{flexGrow: 1}}>Data & Privacy</h2>
                <FormControl className={classes.formControl}>
                    <InputLabel>Status</InputLabel>
                    <Select
                        value={status}
                        onChange={(event: React.ChangeEvent<{ value: unknown }>) => setStatus(event.target.value as string)}>
                            <MenuItem value={'Requested'}>Requested</MenuItem>
                            <MenuItem value={'Completed'}>Completed</MenuItem>
                    </Select>
                </FormControl>
            </div>
            
            <Divider />
            {loading && 
                <LinearProgress
                    className={classes.progressBackground} 
                    classes={{barColorPrimary: classes.progress}}/>
            }

            {!loading &&
                <div>
                    {dataPrivacyQueue.length !== 0 &&
                        <div>
                            <TableContainer component={Paper} style={{marginTop: 16}}>
                                <Table size="small" aria-label="simple table">
                                    <TableHead>
                                    <TableRow>
                                        <StyledTableCell>First name</StyledTableCell>
                                        <StyledTableCell>Last Name</StyledTableCell>
                                        <StyledTableCell>Email</StyledTableCell>
                                        <StyledTableCell>Request Type</StyledTableCell>
                                        <StyledTableCell>Requested At</StyledTableCell>
                                        <StyledTableCell>Status</StyledTableCell>
                                        {status === "Requested" ?
                                            <>
                                                <StyledTableCell>Action</StyledTableCell>
                                                <StyledTableCell>View</StyledTableCell>
                                            </>
                                        :
                                            <>
                                                <StyledTableCell>Completed By</StyledTableCell>
                                                <StyledTableCell>Completed At</StyledTableCell>
                                            </>
                                        }
                                    </TableRow>
                                    </TableHead>
                                    <TableBody>
                                            {dataPrivacyQueue.length !== 0 && dataPrivacyQueue.map((item: DataPrivacyInfo) => (
                                                <StyledTableRow key={item.sk}>
                                                    <StyledTableCell component="th" scope="row">
                                                        {item.firstName}
                                                    </StyledTableCell>
                                                    <StyledTableCell>{item.surname}</StyledTableCell>
                                                    <StyledTableCell>{item.email}</StyledTableCell>
                                                    <StyledTableCell>{getType(item.sk)}</StyledTableCell>
                                                    <StyledTableCell>{FormatDateTime(item.requestedAt)}</StyledTableCell>
                                                    <StyledTableCell>{getStatus(item.pk1)}</StyledTableCell>
                                                    {status === "Requested" ?
                                                        <>  
                                                            <StyledTableCell>
                                                                {getType(item.sk) === "Delete" ?
                                                                    <Button 
                                                                        style={{width: 115}}
                                                                        variant="contained" 
                                                                        color="secondary" 
                                                                        onClick={() => {
                                                                            handleDeleteAction(item)
                                                                        }}>
                                                                        Delete
                                                                    </Button>
                                                                :
                                                                    <Button 
                                                                        style={{width: 115}}
                                                                        variant="contained" 
                                                                        color="primary" 
                                                                        onClick={() => {
                                                                            setOpenConfirmDialog(true)
                                                                            setSelected(item)
                                                                        }}>
                                                                        Complete
                                                                    </Button>
                                                                }
                                                            </StyledTableCell>
                                                            <StyledTableCell>
                                                                <Button variant="contained" color="primary" size="small" 
                                                                    onClick={() => navigate(`/candidates/${item.sk1.split("#")[1]}`)}
                                                                    style={{borderRadius: 16}}>
                                                                    <ArrowForward />
                                                                </Button>
                                                            </StyledTableCell>
                                                        </>
                                                    :
                                                        <>
                                                            <StyledTableCell>{item.completedBy}</StyledTableCell>
                                                            <StyledTableCell>{FormatDateTime(item.completedAt)}</StyledTableCell>
                                                        </>
                                                    }
                                                </StyledTableRow>
                                            ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    }
                </div>
            }

            <ConfirmDialog
                open={openConfirmDialog}
                handleDialog={setOpenConfirmDialog}
                loading={confirmLoading}
                setLoading={setConfirmLoading}
                error={confirmError}
                setError={setConfirmError}
                dialogTitle={selected ? `Complete Data ${getType(selected.sk)} Request` : "Complete Data Request"}
                dialogDescription={selected ? `Are you sure you want to mark this ${getType(selected.sk)} Request by ${selected.firstName} ${selected.surname} as completed` : "Are you sure you want to mark this request as completed?"}
                onSubmit={onConfirmSubmit}
            />

            <DeleteDialog
                open={openDeleteDialog}
                handleDialog={setOpenDeleteDialog}
                selected={selected}
                user={user}
                refreshDataPrivacyQueue={getDataPrivacyQueue} />

            <AlertDialog
                open={openAlertDialog}
                handleDialog={setOpenAlertDialog} />
        </div>
    )
}

interface DeleteDialogProps {
    open: boolean;
    handleDialog: (open: boolean) => void;
    selected?: DataPrivacyInfo
    user?: any | null
    refreshDataPrivacyQueue: () => void;
}

function DeleteDialog({ open, handleDialog, selected, user, refreshDataPrivacyQueue }: DeleteDialogProps) {
    const classes = useStyles()

    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string>()
    const [textFieldValue, setTextFieldValue] = useState<string>('')
    const [disableDelete, setDisableDelete] = useState<boolean>(true)

    useEffect(() => {
        setTextFieldValue('')
        setError('')
    }, [open])

    const onSubmit = () => {
        if(user && selected) {
            setLoading(true)
            let params = {
                body: {
                    id: selected.sk1.split("#")[1],
                    user: user.username,
                    dataPrivacyInfo: selected
                }
            }
            API.post("", "/data-privacy/deleteAllData", params)
            .then(() => {
                setLoading(false)
                handleDialog(false)
                refreshDataPrivacyQueue()
            })
            .catch((error: any) => {
                setLoading(false)
                console.log("Error: unable to delete candidate data", error)
                setError("Error: unable to delete data")
            })
        } else {
            setError("Error: processing request")
        }
    }

    return (
        <Dialog fullWidth maxWidth={'sm'} open={open} onClose={() => handleDialog(false)}>
            <DialogTitle>
                Confirm Delete Data Request
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    WARNING BY CONFIRMING THIS REQUEST THIS USER'S DATA WILL BE DELETED. PROCEED WITH CAUTION
                </DialogContentText>

                <DialogContentText>
                    {selected ?
                        `Type "confirm" in the field below to delete ${selected?.firstName} ${selected?.surname}'s data`
                    :
                        `Type "confirm" in the field below to delete candidate's data`
                    }
                </DialogContentText>

                <TextField
                    fullWidth
                    placeholder="Type confirm"
                    variant="filled"
                    value={textFieldValue}
                    onChange={(e) => {
                        let v = e.target.value as string
                        setTextFieldValue(v)
                        if(v.toLowerCase() === "confirm") {
                            setDisableDelete(false)
                        } else {
                            setDisableDelete(true)
                        }
                    }}/>
            </DialogContent>
            <DialogActions>
                {error &&
                    <span style={{color: "red", marginRight: 8}}>{error}</span>
                }
                <Button variant="contained" color="secondary" className={classes.actionButton} onClick={() => handleDialog(false)}>
                    No
                </Button>
                {loading ?
                    <Button variant="contained" color="primary" className={classes.actionButton}>
                        <CircularProgress size={25} style={{color: "white"}} />
                    </Button>
                :
                    <Button onClick={() => onSubmit()} variant="contained" color="primary" className={classes.actionButton} disabled={disableDelete}>
                        Yes
                    </Button>
                }
            </DialogActions>
        </Dialog>
    )
}

interface AlertDialogProps {
    open: boolean;
    handleDialog: (open: boolean) => void;
}


function AlertDialog({ open, handleDialog }: AlertDialogProps) {
    const classes = useStyles()

    return (
        <Dialog fullWidth maxWidth={'sm'} open={open} onClose={() => handleDialog(false)}>
            <DialogTitle>
                Unable to perform action
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    You cannot perform this action because this candidate has an outstanding privacy request that must be completed first.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleDialog(false)} variant="contained" color="primary" className={classes.actionButton}>
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    )
    
}