import React, { useState, useEffect } from 'react'
import { 
    Divider, Table, TableContainer, TableHead, TableRow, TableBody, Paper,
    Button, LinearProgress, FormControl, InputLabel, MenuItem, Select
} from "@material-ui/core";
import { ArrowForward } from "@material-ui/icons";
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { API } from "aws-amplify";
import { StyledTableCell, StyledTableRow } from '../components/StyledTable';
import { FormatDateTime } from '../utils';
import { ConfirmDialog } from '../components/ConfirmDialog';
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) => 
    createStyles({
        progress: {
            backgroundColor: "#ff9800"
        },
        progressBackground: {
            backgroundColor: "#ffe0b2"
        },
        formControl: {
            margin: theme.spacing(2),
            minWidth: 120,
        },
        actionButton: {
            marginRight: 8,
            marginBottom: 8,
            width: 100
        }
    })
);

interface Props {
    user?: any | null
}

interface FastTrackRequestInfo {
    pk: string;
    sk: string;
    pk1: string;
    sk1: string;
    requestedAt: string;
    firstName: string;
    surname: string;
    requestedBy: string;
    completedBy: string;
    completedAt: string;
}

export default function FastTrackQueue({ user }: Props) {
    const classes = useStyles()
    
    const [loading, setLoading] = useState<boolean>(false)
    const [FastTrackRequestQueue, setFastTrackRequestQueue] = useState<Array<FastTrackRequestInfo>>([])
    const [status, setStatus] = useState<string>('Requested')

    const navigate = useNavigate()

    useEffect(() => {
        setLoading(true)
        const s = (status === "Requested") ? "request" : "completed";
        API.get("", `/fasttrack/queue/${s}`, "")
        .then((response: Array<FastTrackRequestInfo>) => {
            if(response) {
                if(status === "Requested") {
                    setFastTrackRequestQueue(response.sort((a, b) => (a.requestedAt < b.requestedAt) ? 1 : -1))
                } else {
                    setFastTrackRequestQueue(response.sort((a, b) => (a.completedAt < b.completedAt) ? 1 : -1))
                }
                
            }
            setLoading(false)
        })
        .catch((error: any) => {
            setLoading(false)
            console.log("Error: fetching data privacy queue", error)
        })
    }, [status])


    const getStatus = (status: string) => {
        if(status.includes("REQUESTED")) {
            return "Requested"
        }
        return "Completed"
    }

    return (
        <div>
            <div style={{display: "flex"}}>
                <h2 style={{flexGrow: 1}}>FastTrack Queue</h2>
                <FormControl className={classes.formControl}>
                    <InputLabel>Status</InputLabel>
                    <Select
                        value={status}
                        onChange={(event: React.ChangeEvent<{ value: unknown }>) => setStatus(event.target.value as string)}>
                            <MenuItem value={'Requested'}>Requested</MenuItem>
                            <MenuItem value={'Completed'}>Completed</MenuItem>
                    </Select>
                </FormControl>
            </div>
            
            <Divider />
            {loading && 
                <LinearProgress
                    className={classes.progressBackground} 
                    classes={{barColorPrimary: classes.progress}}/>
            }

            {!loading &&
                <div>
                    {FastTrackRequestQueue.length !== 0 &&
                        <div>
                            <TableContainer component={Paper} style={{marginTop: 16}}>
                                <Table size="small" aria-label="simple table">
                                    <TableHead>
                                    <TableRow>
                                        <StyledTableCell>First name</StyledTableCell>
                                        <StyledTableCell>Last Name</StyledTableCell>
                                        <StyledTableCell>Requested At</StyledTableCell>
                                        <StyledTableCell>Requested By</StyledTableCell>
                                        <StyledTableCell>Status</StyledTableCell>
                                        {status === "Requested" ?
                                            <>
                                                <StyledTableCell>View</StyledTableCell>
                                            </>
                                        :
                                            <>
                                                <StyledTableCell>Completed By</StyledTableCell>
                                                <StyledTableCell>Completed At</StyledTableCell>
                                            </>
                                        }
                                    </TableRow>
                                    </TableHead>
                                    <TableBody>
                                            {FastTrackRequestQueue.length !== 0 && FastTrackRequestQueue.map((item: FastTrackRequestInfo) => (
                                                <StyledTableRow key={item.sk}>
                                                    <StyledTableCell component="th" scope="row">
                                                        {item.firstName}
                                                    </StyledTableCell>
                                                    <StyledTableCell>{item.surname}</StyledTableCell>
                                                    <StyledTableCell>{FormatDateTime(item.requestedAt)}</StyledTableCell>
                                                    <StyledTableCell>{item.requestedBy}</StyledTableCell>
                                                    <StyledTableCell>{getStatus(item.pk1)}</StyledTableCell>
                                                    {status === "Requested" ?
                                                        <>
                                                            <StyledTableCell>
                                                                <Button variant="contained" color="primary" size="small" 
                                                                    onClick={() => navigate(`/candidates/${item.sk1.split("#")[1]}`)}
                                                                    style={{borderRadius: 16}}>
                                                                    <ArrowForward />
                                                                </Button>
                                                            </StyledTableCell>
                                                        </>
                                                    :
                                                        <>
                                                            <StyledTableCell>{item.completedBy}</StyledTableCell>
                                                            <StyledTableCell>{FormatDateTime(item.completedAt)}</StyledTableCell>
                                                        </>
                                                    }
                                                </StyledTableRow>
                                            ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    }
                </div>
            }
        </div>
    )
}