import React, { useState, useEffect } from 'react'
import { 
    makeStyles, createStyles 
} from '@material-ui/core/styles';
import { 
    Divider, LinearProgress, Table, TableContainer, TableRow,
    Paper, TableHead, TableBody, Button, TextField, InputAdornment,
    IconButton
} from "@material-ui/core";
import { API } from "aws-amplify";
import { StyledTableCell, StyledTableRow } from '../../components/StyledTable';
import { 
    ArrowForward, ClearAll, Search
} from "@material-ui/icons"
import { useNavigate } from "react-router-dom";
import { FormatDateTime } from '../../utils';

let sTimer: NodeJS.Timeout;

const useStyles = makeStyles(() => 
    createStyles({
        header: {
            display: "flex"
        },
        table: {
            minWidth: 650,
        },
        progress: {
            backgroundColor: "#ff9800"
        },
        progressBackground: {
            backgroundColor: "#ffe0b2"
        },
        search: {
            width: 300
        },
    })
);
interface Props {
    user: any | null
}

export interface ActiveJobs {
    pk: string;
    sk: string;
    activeJobList: Array<Job>
    updatedAt: string
}

export interface Job {
    Reference: string;
    Title: string;
    ShortDescription: string;
    AdDetail: string;
    Consultant: string;
    ContactNumber: string;
    ContactEmail: string;
    CompanyName: string;
    Location: string;
    JobType: string;
    Classification: string;
    Position: string;
    LinkOutUrl: string;
    ApplicationEmail: string;
    SalaryType: string;
    SalaryMin: string;
    SalaryMax: string;
    SalaryCurrency: string;
    ResidentOnly: string;
    timestamp: string;
    updatedAt: string
}

export default function Jobs({ user }: Props) {

    const classes = useStyles()

    const navigate = useNavigate()

    const [loading, setLoading] = useState<boolean>(false)
    const [jobs, setJobs] = useState<Array<Job>>([])
    const [filteredJobs, setFilteredJobs] = useState<Array<Job>>([])
    const [searchInput, setSearchInput] = useState<string>('')

    useEffect(() => {
        setLoading(true)
        API.get("", "/allJobs", {})
        .then((response: Array<Job>) => {
            if(response) {
                let jobs = response.sort((a,b) => (parseInt(a.timestamp) > parseInt(b.timestamp)) ? -1 : 1)
                setJobs(jobs)
                setFilteredJobs(jobs)
            }
            setLoading(false)
        })
        .catch((error: any) => {
            console.log("Unable to fetch jobs", error)
            setLoading(false)
        })
    }, [])

    const handleSearch = (input: string) => {
        setSearchInput(input)
        if (sTimer !== null) {
            clearTimeout(sTimer);
        }
        sTimer = setTimeout(() => {
            if (input.length > 0) {
                setFilteredJobs(jobs.filter((j: Job) => j.Reference.includes(input) || j.Title.toLowerCase().includes(input.toLowerCase())))
            } else {
                setFilteredJobs(jobs)
            }
        }, 1500);
    }

    const handleClearSearch = () => {
        setSearchInput('')
        setFilteredJobs(jobs)
    }

    return (
        <div>
            <div className={classes.header}>
                <h2 style={{flexGrow: 1}}>
                    Jobs
                </h2>
                <div>
                    <TextField
                        style={{marginTop: 8}}
                        label="Search by Job Ref or Title"
                        onChange={(event) => handleSearch(event.target.value as string)}
                        value={searchInput}
                        InputProps={{
                            className: classes.search,
                            startAdornment: <InputAdornment position="start">
                                <Search />
                            </InputAdornment>,
                            endAdornment: <InputAdornment position="end">
                                <IconButton color="inherit" onClick={handleClearSearch}>
                                    <ClearAll />
                                </IconButton>
                            </InputAdornment>
                        }} />
                </div>
            </div>
            <Divider />
            {loading && 
                <LinearProgress
                    className={classes.progressBackground} 
                    classes={{barColorPrimary: classes.progress}}/>
            }

            <div style={{marginTop: 24}}>
                {filteredJobs.length !== 0 && 
                    <TableContainer component={Paper}>
                        <Table className={classes.table} size="small" aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>Job Reference</StyledTableCell>
                                    <StyledTableCell>Title</StyledTableCell>
                                    <StyledTableCell>Classification</StyledTableCell>
                                    <StyledTableCell>Position</StyledTableCell>
                                    <StyledTableCell>Location</StyledTableCell>
                                    <StyledTableCell>JobType</StyledTableCell>
                                    <StyledTableCell>Updated At</StyledTableCell>
                                    <StyledTableCell>View</StyledTableCell>
                                    
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {filteredJobs.length !== 0 && filteredJobs.map((job: Job) => (
                                <StyledTableRow key={job.Reference}>
                                    <StyledTableCell component="th" scope="row">
                                        {job.Reference}
                                    </StyledTableCell>
                                    <StyledTableCell>{job.Title}</StyledTableCell>
                                    <StyledTableCell>{job.Classification}</StyledTableCell>
                                    <StyledTableCell>{job.Position}</StyledTableCell>
                                    <StyledTableCell>{job.Location}</StyledTableCell>
                                    <StyledTableCell>{job.JobType}</StyledTableCell>
                                    <StyledTableCell>{FormatDateTime(job.updatedAt)}</StyledTableCell>
                                    <StyledTableCell>
                                            <Button variant="contained" color="primary" size="small" 
                                                onClick={() => navigate(`/jobs/${job.Reference}`)}
                                                style={{borderRadius: 16}}>
                                                <ArrowForward />
                                            </Button>
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                }
            </div>
        </div>
    )
}
