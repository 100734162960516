import React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { 
    Table, TableContainer, TableHead, TableRow, TableBody,
    Button, Chip, Paper
} from "@material-ui/core"; 
import { ArrowForward } from "@material-ui/icons"
import { useNavigate } from 'react-router-dom'
import { Candidate } from '../../types/CandidateTypes';
import { StyledTableCell, StyledTableRow } from '../../components/StyledTable';
import { GetCandidateStatusColor } from '../../utils';

const useStyles = makeStyles((theme: Theme) => 
    createStyles({
        header: {
            display: "flex"
        },
        table: {
            minWidth: 650
        },
        margin: {
            margin: theme.spacing(1),
        },
        input: {
            width: 350
        },
        skillSearchButton: {
            marginTop: 16,
            marginBottom: 8
        },
        progress: {
            backgroundColor: "#ff9800"
        },
        progressBackground: {
            backgroundColor: "#ffe0b2"
        },
        skillSearchAutocomplete: {
            marginTop: 16, 
            marginBottom: 8,
            marginRight: 8,
            width: 400
        },
        selectedSkillChips: {
            display: "flex",
            flexWrap: "wrap"
        },
        selectedSkillChip: {
            marginRight: 8,
            marginBottom: 8,
            backgroundColor: "#fff9c4"
        }
    })
);

interface Props {
    loading: boolean
    candidatesList: Array<Candidate>
    skillSearch: boolean
    selectedTags: Array<string>
}

export default function CandidateTable({ loading, candidatesList, skillSearch, selectedTags }: Props) {

    const classes = useStyles()

    const navigate = useNavigate()

    const handleApplicationClick = (applicantId: string) => {
        navigate(`${applicantId}`)
    }

    const getCandidateMatch = (score: number, numSkills: number) => {
        if(score === 0 || numSkills === 0) {
            return "0%"
        }

        return Math.round((score/numSkills)*100) + "%"
    }

    return (
        <>
            {(!loading && candidatesList.length !== 0) &&
                <div>
                        <TableContainer component={Paper} style={{marginTop: 16}}>
                            <Table className={classes.table} size="small" aria-label="simple table">
                                <TableHead>
                                <TableRow>
                                    <StyledTableCell>Title</StyledTableCell>
                                    <StyledTableCell>First Name</StyledTableCell>
                                    <StyledTableCell>Last Name</StyledTableCell>
                                    <StyledTableCell>Email</StyledTableCell>
                                    <StyledTableCell>Phone</StyledTableCell>
                                    <StyledTableCell>Location</StyledTableCell>
                                    <StyledTableCell>Status</StyledTableCell>
                                    {skillSearch &&
                                        <StyledTableCell>Match</StyledTableCell>
                                    }
                                    <StyledTableCell>View</StyledTableCell>
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                        {candidatesList.length !== 0 && candidatesList.map((candidate: Candidate) => (
                                            <StyledTableRow key={candidate.username}>
                                                <StyledTableCell component="th" scope="row">
                                                    {candidate.title}
                                                </StyledTableCell>
                                                <StyledTableCell>{candidate.firstName}</StyledTableCell>
                                                <StyledTableCell>{candidate.surname}</StyledTableCell>
                                                <StyledTableCell>{candidate.email}</StyledTableCell>
                                                <StyledTableCell>{candidate.phone}</StyledTableCell>
                                                <StyledTableCell>{candidate.location}</StyledTableCell>
                                                <StyledTableCell>
                                                    <Chip 
                                                        label={candidate.status.toUpperCase()}
                                                        style={{backgroundColor: GetCandidateStatusColor(candidate.status), color: "white", fontWeight: "bold"}}
                                                        size="small" />
                                                </StyledTableCell>
                                                {skillSearch &&
                                                    <StyledTableCell>
                                                        {getCandidateMatch(candidate.score, selectedTags.length)}
                                                    </StyledTableCell>
                                                }
                                                <StyledTableCell>
                                                        <Button variant="contained" color="primary" size="small" 
                                                            onClick={() => handleApplicationClick(candidate.username)}
                                                            style={{borderRadius: 16}}>
                                                            <ArrowForward />
                                                        </Button>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                </div>  
            }
            {(!loading && candidatesList.length === 0 )&&
                <div>
                    <p>No results found.</p>
                </div>
            }
        </>
    )
}
