import React, { useEffect, useState } from 'react'
import { 
    makeStyles, createStyles, Theme 
} from '@material-ui/core/styles';
import { 
    Divider, LinearProgress, Menu, MenuItem
} from "@material-ui/core";
import { useNavigate } from "react-router-dom"
import { Calendar, dateFnsLocalizer, View } from "react-big-calendar";
import { format, parse, startOfWeek, getDay } from "date-fns";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { API } from "aws-amplify";
import { Application } from '../../types/CandidateTypes';
import { InterviewInfo } from '../../types/InterviewTypes';
import EditInterviewInviteDialog from './EditInterviewInviteDialog';
import CancelInterviewDialog from '../applications/CancelInterviewDialog';
var tinycolor = require("tinycolor2")

const useStyles = makeStyles((theme: Theme) => 
    createStyles({
        header: {
            display: "flex"
        },
        progress: {
            backgroundColor: "#ff9800"
        },
        progressBackground: {
            backgroundColor: "#ffe0b2"
        },
    })
);

interface InterviewsProps {
    user: any | null
}

export default function Interviews({ user }: InterviewsProps) {

    const classes = useStyles()
    const [loading, setLoading] = useState<boolean>(false)
    const [interviews, setInterviews] = useState<Array<InterviewInfo>>([])
    const [jobColours, setJobColours] = useState<Map<number, string>>()

    const [startDate, setStartDate] = useState<string>()
    const [endDate, setEndDate] = useState<string>()

    const [interviewMenu, setInterviewMenu] = useState<null | HTMLElement>(null)
    const [selectedEvent, setSelectedEvent] = useState<InterviewInfo>()

    const [showInviteDialog, setShowInviteDialog] = useState<boolean>(false)
    const [showCancelInterviewDialog, setShowCancelInterviewDialog] = useState<boolean>(false)

    const navigate = useNavigate()

    const locales = {
       'en-NZ' : require('date-fns/locale/en-NZ')
    }

    const localizer = dateFnsLocalizer({
        format,
        parse,
        startOfWeek,
        getDay,
        locales
    })

    const resourceMap = [
        { resourceId: "AUCKLAND", resourceTitle: "Auckland" },
        { resourceId: "WELLINGTON", resourceTitle: "Wellington" },
        { resourceId: "SILVERDALE", resourceTitle: "Silverdale" }
    ]

    const minTime = new Date()
    minTime.setHours(7, 0, 0, 0)

    const maxTime = new Date()
    maxTime.setHours(20, 0, 0, 0)

    useEffect(() => {
        
        let startOfWeek = getStartOfWeek()
        let endOfWeek = getEndOfWeek()

        setStartDate(startOfWeek)
        setEndDate(endOfWeek)

       getInterviewsByDateRange(startOfWeek, endOfWeek)

    }, [])

    const getInterviewsByDateRange = (from?: string, to?: string) => {
        
        let fromDate = ""
        let toDate = ""

        if(from && to) {
            fromDate = from
            toDate = to
        } else if (startDate && endDate) {
            fromDate = startDate
            toDate = endDate
        }
        
        if(fromDate && toDate) {
            setLoading(true)

            API.get("", `/interviews/from/${fromDate}/to/${toDate}`, {})
            .then((response: Array<InterviewInfo>) => {
                if(response) {
                    response.forEach((i: InterviewInfo) => {
                        let startDate = new Date(i.interviewAt)
                        let endDate = new Date(i.interviewAt)
                        endDate.setHours(endDate.getHours() + 1)
    
                        i.id = i.sk2
                        i.title = i.interviewTitle
                        i.resourceId = i.location
                        i.start = startDate
                        i.end = endDate
                    })
                    setInterviews(response)
    
    
                    // API.get("", '/jobApplications/invite', {})
                    // .then((applications: Array<Application>) => {
                    //     if(applications) {
                            
                    //         response.forEach((i: InterviewInfo) => {
                    //             let startDate = new Date(i.interviewAt)
                    //             let endDate = new Date(i.interviewAt)
                    //             endDate.setHours(endDate.getHours() + 1)
            
                    //             i.title = i.interviewTitle
                    //             i.resourceId = i.location
                    //             i.start = startDate
                    //             i.end = endDate
                    //         })
                    //         setInterviews(response)
    
                    //         // let jc = new Map<number, string>()
    
                    //         // applications.forEach((i: Application) => {
                    //         //     let colour = getRandomColour()
                    //         //     jc.set(i.jobRef, colour)
                    //         // })
                    //         // //console.log(jc)
                    //         // setJobColours(jc)
    
                    //         setLoading(false)
                    //     } else {
                    //         setLoading(false)
                    //     }
                    // })
                    // .catch((error: any) => {
                    //     console.log("Error fetching applications by status", error)
                    //     setLoading(false)
                    // })
                }
            })
            .catch((error: any) => {
                console.log("Error fetching interviews by date range", error)
                
            })
            .finally(()=>{
                setLoading(false)

            })
            
        }
    }

    const getRandomColour = (): string => {
        
        const randomColor = Math.floor(Math.random()*16777215).toString(16);

        var color = tinycolor(randomColor)
        if(color.isLight()) {
            return "#" + randomColor;
        } else {
            return color.lighten(50).toString()
        }
    }

    const getStartOfWeek = (): string => {
        const d = new Date();
        let diff = d.getDate() - d.getDay() + 1;
        d.setDate(diff);
        d.setHours(0, 0, 0, 0);

        return d.toISOString()
    }

    const getEndOfWeek = (): string => {
        const d = new Date();
        let diff = d.getDate() - d.getDay() + 7;
        d.setDate(diff);
        d.setHours(0, 0, 0, 0);

        return d.toISOString()
    }

    const onRangeChange = (range: Date[]| {
        start: string | Date;
        end:  string | Date;
    }, view: View | undefined) => {
        if(range instanceof Array) {

            let startDate = range[0]
            let endDate = new Date(range[range.length-1])

            endDate.setDate(endDate.getDate() + 1)

            setStartDate(startDate.toISOString())
            setEndDate(endDate.toISOString())

            getInterviewsByDateRange(startDate.toISOString(), endDate.toISOString())


        } else if (range instanceof Object) {
            let startDate = ""
            let endDate = ""

            if(typeof range.start === 'string') {
               startDate = new Date(range.start).toISOString()
                
            } else if(range.start instanceof Date) {
                startDate = range.start.toISOString()
            }

            if(typeof range.end === "string") {
                endDate = new Date(range.end).toISOString()
            
            } else if(range.end instanceof Date) {
                endDate = range.end.toISOString()
            }

            if(startDate !== "" && endDate !== "") {
                getInterviewsByDateRange(startDate, endDate)

                setStartDate(startDate)
                setEndDate(endDate)
            }
        }
    }

    return (
        <div>
            <div className={classes.header}>
                <h2 style={{flexGrow: 1}}>
                    Interviews
                </h2>
                <div>
                </div>
            </div>
            <Divider />
            {loading && 
                <LinearProgress
                    className={classes.progressBackground} 
                    classes={{barColorPrimary: classes.progress}}/>
            }

            <div style={{marginTop: 16}}>

                <Calendar
                    localizer={localizer}
                    events={interviews}
                    defaultView='work_week'
                    views={['day', 'work_week']}
                    startAccessor="start"
                    endAccessor="end"
                    onRangeChange={onRangeChange}
                    style={{ height: 1000 }}
                    min={minTime}
                    max={maxTime}
                    resources={resourceMap}
                    resourceIdAccessor="resourceId"
                    resourceTitleAccessor="resourceTitle"
                    onSelectEvent={(event, e) => {
                        setInterviewMenu(e.currentTarget)
                        setSelectedEvent(event)
                    }}
                    eventPropGetter={(event) => {
                        
                        let jobColour = jobColours?.get(event.sk1)

                        if(jobColour) {
                            let newStyle = {
                                backgroundColor: jobColour,
                                color: 'black',
                                borderRadius: "0px",
                                border: "none"
                              };

                            return {
                                style: newStyle
                            }
                        }
                        
                        return {}
                    }}
                />

                <Menu
                    open={Boolean(interviewMenu)} 
                    anchorEl={interviewMenu}
                    onClose={() => setInterviewMenu(null)}>

                    <MenuItem onClick={() => {
                        setInterviewMenu(null)
                        navigate(`/candidates/${selectedEvent?.sk.split("#")[1]}`)
                    }}>
                        Conduct Interview
                    </MenuItem>
                    <MenuItem onClick={() => {
                        setShowInviteDialog(true)
                        setInterviewMenu(null)
                    }}>
                        Edit
                    </MenuItem>
                    <MenuItem onClick={() => {
                        setShowCancelInterviewDialog(true)
                        setInterviewMenu(null)
                    }}>
                        Cancel
                    </MenuItem>
                </Menu>

                <EditInterviewInviteDialog 
                    open={showInviteDialog}
                    handleDialog={setShowInviteDialog}
                    user={user}
                    refreshData={getInterviewsByDateRange}
                    interview={selectedEvent} />

                <CancelInterviewDialog
                    openDialog={showCancelInterviewDialog}
                    handleDialog={setShowCancelInterviewDialog}
                    user={user}
                    interview={selectedEvent}
                    refreshData={getInterviewsByDateRange}
                />
            </div>            
        </div>
    )
}
